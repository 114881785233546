<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            
            <div class="">
                     <span class="font-semibold text-base">Peringatan sensor</span>
                    <div>
                        <div class="mt-4 grid grid-cols-12 gap-4">
                       <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                       <div class="col-span-12">
                           <div v-if="device_detail.devices == 'level'">
                               <div class="flex items-center">
                                   <div class="w-20">Status siaga</div>  <input type="checkbox" name="" v-model="device_detail.status_siaga" id="">

                               </div>       
                               <div v-if="device_detail.status_siaga">
                                   <div class="flex items-center mt-2">
                                       <div class="w-20">Siaga 4</div> <input class="block border border-gray-200 p-2 px-4 rounded text-xs" type="text" v-model="device_detail.siaga_4">cm
                                   </div>
                                   <div class="flex items-center mt-2">
                                       <div class="w-20">Siaga 3</div> <input class="block border border-gray-200 p-2 px-4 rounded text-xs" type="text" v-model="device_detail.siaga_3">cm
                                   </div>
                                    <div class="flex items-center mt-2">
                                       <div class="w-20">Siaga 2</div> <input class="block border border-gray-200 p-2 px-4 rounded text-xs" type="text" v-model="device_detail.siaga_2">cm
                                   </div>
                                   <div class="flex items-center mt-2">
                                       <div class="w-20">Siaga 1</div> <input class="block border border-gray-200 p-2 px-4 rounded text-xs" type="text" v-model="device_detail.siaga_1">cm
                                   </div>
                               </div>
                           </div>

                           <div class="mt-4 w-full">
                                <button class="text-white rounded text-xs bg-green-500 px-2 py-2 mr-2" @click="addDevice()">Tambah Peringantan</button>
                                <button class="text-white rounded text-xs bg-blue-500 px-2 py-2" @click="savePostData()" v-if="device_detail.devices == 'level'">Simpan Siaga</button>
                                <table class="table-auto w-full text-xs">
                                    <tr class="text-left">
                                        <th class=" text-gray-500 py-2 px-4">Parameter</th>
                                        <th class=" text-gray-500 py-2 px-4">Lebih atau kurang dari</th>
                                        <th class=" text-gray-500 py-2 px-4">Nilai</th>
                                        <th class=" text-gray-500 py-2 px-4">Satuan</th>
                                        <th class=" text-gray-500 py-2 px-4">Aksi</th>
                                        
                                    </tr>
                                
                                    <tr v-for="(item,index) in data" :key="index" :class="`cursor-pointer ${index %2 == 0 ? 'bg-gray-100 ' : ' '} hover:bg-gray-100`">
                                        <td class="text-gray-500 py-2 px-4">
                                             <v-select @input="getSatuan(index)" :options="device" v-model="item.device_param_id" :reduce="device => device.id" label="name" >
                                                 <template v-slot:option="option">
                                                    
                                                    {{ option.name}}
                                                </template>
                                                 <template #selected-option="{ name }">
                                                     {{ name }}
                                                </template>
                                             </v-select>
                                        </td>
                                        <td class="text-gray-500 py-2 px-4">
                                                 <select ref="prov_id" name="prov_id" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" v-model="item.greater_less" >
                                                    <option value="0" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" v-html="`<=`"></option>
                                                    <option value="1" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" >{{`>=`}}</option>
                                                </select>
                          
                                        </td>
                                          <td class="text-gray-500 py-2 px-4">
                                                <input
                                                v-model="item.value_param"
                                                type="text"
                                                :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs`"
                                                name="start_date"
                                                ref="start_date"
                                                id="start_date"
                                                placeholder="Nilai" />
                                        </td>
                                        
                                         <td class="text-gray-500 py-2 px-4">
                                             {{item.satuan}}
                                        </td>
                                        <td class="">
                                            <button class="text-white shadow rounded mr-1 h-8 w-8 text-xs bg-green-500 inline" @click="saveDevice(item.id,item)" >
                                                <i class="far fa-save"></i>
                                            </button>

                                            <button class="text-white shadow rounded h-8 w-8 text-xs bg-red-600" @click="deleteDevice(item.id,index)">
                                                <i class="far fa-times" ></i>
                                            </button>
                                            
                                        </td>
                                                
                                    </tr>
                                </table>
                            </div>

                       </div>
                    </div>
                    </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        device:[],
        device_detail:'',
       
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
        addDevice(){
            this.data.push({
                device_id:this.$route.query.device_id,
                device_param_id:'',
                satuan:'',
                value_param:0,
                greater_less:0,
                id:''
            })
        },
        saveDevice(id,item) {
            if (id != '') {
                this.axios.put('v2/device_alert/' +id,item,this.config)
                .catch((err) => {
                    console.log(err)
                })
            } else {
                let {id, ...param} = item 
                this.axios.post('v2/device_alert',param,this.config)
                .catch((err) => {
                  console.log(err)
                })
            }

            this.$snack.success({
                text: 'Berhasil Simpan Data',

            })
        },
        deleteDevice(id,index){
            this.data.splice(index,1)
            if (id) {
                this.axios.delete('v2/device_alert/'+id,this.config)
                .then((ress)=>{
                    console.log(ress)
                }) 

            }
        },
        async getParam() {
            this.axios.get('v2/device_param/get-param-device?code=' + this.$route.query.code,this.config)
            .then((ress) => {
                this.device = ress.data.Device_params
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
                this.errorResponse(err)
            })
        },
        getSatuan(index) {
            let device_param = this.data[index].device_param_id
            device_param = this.device.find(x => x.id === this.data[index].device_param_id)
            this.data[index].satuan = device_param.satuan
        },
        async getDeviceDetail() {
            await this.axios.get('v1/device/' + this.$route.query.device_id,this.config)
            .then((ress) => {
                this.device_detail = ress.data
                this.device_detail.siaga_1 = parseInt(this.device_detail.siaga_1 * 100)
                this.device_detail.siaga_2 = parseInt(this.device_detail.siaga_2 * 100)
                this.device_detail.siaga_3 = parseInt(this.device_detail.siaga_3 * 100)
                this.device_detail.siaga_4 = parseInt(this.device_detail.siaga_4 * 100)
            })
             .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
                this.errorResponse(err)
            })
        },
         async savePostData(){
            let data = new FormData()
            var request_form = {...this.device_detail}
            request_form.siaga_1 = request_form.siaga_1/100
            request_form.siaga_2 = request_form.siaga_2/100
            request_form.siaga_3 = request_form.siaga_3/100
            request_form.siaga_4 = request_form.siaga_4/100
            await Object.keys(this.device_detail).forEach(function(key,val) {
                console.log(key)
                if (key != 'foto') {
                    data.append(key,request_form[key])
                    
                }
            });

            this.axios.put('v1/device/' + this.$route.query.device_id,data,this.config)
            .then((ress) => {
                this.$snack.success({
                    text: ress.data.message,
                })
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
           
        },
        async getData(){
           

            this.axios.get('v2/device_alert?device_id=' + this.$route.query.device_id,this.config)
            .then((ress) => {
                this.data = []
                ress.data.rows.forEach(element => {
                    this.data.push({
                          device_id:element.device_id,
                        device_param_id:element.device_param_id,
                        satuan:element.Device_param.satuan,
                        value_param:parseFloat(element.value_param),
                        greater_less:element.greater_less == false ? 0 : 1,
                        id:element.id
                    })
                });
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
                this.errorResponse(err)
            })
           
        },
    
    },
    computed:{
      
    },
    created() {
        this.getData()
        this.getParam()
        this.getDeviceDetail()
    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>